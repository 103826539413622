<template>
  <PDialog
    :loading="isLoading"
    :title="title"
    classes="w-10/12 md:w-4/5 xl:w-3/5"
    @close="$emit('close')"
  >
    <div
      class="flex flex-1 overflow-y-hidden"
      v-if="container"
    >

      <div class="p-4 overflow-y-auto bg-gray-100 border-r border-gray-300 w-72">

        <PLoader v-if="isLoadingContainer" />

        <div
          v-else
          class="flex flex-col space-y-4"
        >
          <PText label="Status">{{ container.status }}</PText>
          <PText label="Leie ID">{{ container.containerRentalId }}</PText>
          <PText label="Container ID">{{ container.id }}</PText>
          <PText label="Lagerlokasjon">{{ container.locationNameCurrent || container.locationIdCurrent }}</PText>
          <PText
            label="Referanse"
            v-if="container.customerReference"
          >{{ container.customerReference }}</PText>
          <PText
            label="Innhold"
            v-if="container.containerContent"
          >{{ container.containerContent }}</PText>
        </div>

      </div>

      <div class="relative flex-1 p-4 overflow-y-scroll bg-gray-200">

        <div class="flex justify-end mb-4">
          <PButton @click="orderModalVisible = true">
            Ny tjeneste
          </PButton>
        </div>

        <PInlineModal v-if="orderModalVisible">

          <div class="font-semibold">{{ this.selectedOrderId ? "Endre tjeneste" : "Ny tjeneste" }}</div>

          <div class="flex flex-col mt-4 space-y-4">

            <PSelect
              v-model="selectedOrderTypeId"
              :disabled="isCreatingOrder"
              :items="orderTypes"
              class="w-full"
              itemText="name"
              itemValue="id"
              label="Tjeneste"
              placeholder="Velg tjenste"
              v-slot="{ item }"
            >
              {{ item.name }}
            </PSelect>

            <PDatePicker
              v-model="deliveryDate"
              :required="true"
              label="Dato"
            />

            <PInput
              v-model="customerReference"
              label="Referanse"
            />

            <PTextarea
              v-model="customerComment"
              class="w-64"
              label="Kommentar"
            />

          </div>

          <div class="flex items-center justify-between mt-8 space-x-4">

            <PButton
              :loading="isCreatingOrder"
              color="secondary"
              @click="onCancelCreate"
            >
              Avbryt
            </PButton>

            <PButton
              :disabled="!selectedOrderTypeId"
              :loading="isCreatingOrder"
              @click="onCreateOrder"
            >
              {{ this.selectedOrderId ? "Lagre" : "Bestill" }}
            </PButton>

          </div>

        </PInlineModal>

        <PLoader v-if="isLoadingOrders" />

        <div
          v-else
          class="flex flex-col space-y-4"
        >

          <div
            v-for="(order, i) in orders"
            :key="i"
            class="p-4 bg-white rounded shadow-sm"
          >
            <div class="lg:flex lg:items-center lg:justify-between">
              <div>
                <div class="font-semibold">{{ order.containerOrderTypeName }}</div>
                <div class="text-xs">{{ order.createdLocal | dateAndTime }}</div>
                <div class="text-xs">{{ order.createdBy.name }}</div>
                <div
                  v-if="order.customerReference"
                  class="text-xs"
                >{{ order.customerReference }}</div>
                <div
                  v-if="order.customerComment"
                  class="text-xs"
                >{{ order.customerComment }}</div>
                <a
                  class="text-xs underline"
                  href
                  @click.prevent="onDocuments(order)"
                >Dokumenter</a>
                <a
                  v-if="order.status === 'Registered'"
                  class="ml-2 text-xs underline"
                  href
                  @click.prevent="onEdit(order)"
                >Endre</a>
                <a
                  v-if="order.status === 'Registered'"
                  class="ml-2 text-xs underline"
                  href
                  @click.prevent="onDelete(order)"
                >Slett</a>
              </div>
              <div
                class="inline-block px-4 py-1 mt-2 text-sm font-semibold rounded-full lg:mt-0"
                :class="{
                  'bg-orange-200 text-orange-600': order.status === 'Registered',
                  'bg-green-100 text-green-600': order.status === 'Open',
                  'bg-gray-200 text-gray-600': order.status === 'Closed',
                }"
              >
                {{ order.status === "Registered" ? "Registrert" :
                  order.status === "Open" ? "Åpen" : order.status
                }}
              </div>
            </div>
            <div
              v-if="order.transactions.length > 0"
              class="flex flex-col mt-4 rounded space-y-4"
            >
              <div class="text-sm leading-relaxed lg:table">
                <div
                  v-for="(transaction, j) in order.transactions"
                  :key="j"
                  class="mt-2 lg:table-row"
                >
                  <div class="pr-2 font-semibold lg:table-cell">{{ transaction.transactionTypeName }}</div>
                  <div class="pr-2 lg:table-cell">{{ transaction.createdLocal | dateAndTime }}</div>
                  <div class="pr-2 lg:table-cell">{{ transaction.createdBy.name }}</div>
                  <div class="lg:table-cell lg:text-right">{{ formatNumber(transaction.amount) }},-</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <PDialogActions>
      <PButton
        :disabled="isSubmitting"
        color="secondary"
        @click="onClose"
      >
        Lukk
      </PButton>
    </PDialogActions>
  </PDialog>
</template>

<script>

import http from "@/utilities/http"
import { filter, pick } from "lodash"
import { format } from "date-fns"
import PInlineModal from "../components/PInlineModal"

export default {

  components: {
    PInlineModal
  },

  props: {
    id: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      container: null,
      orders: [],
      orderTypes: [],
      selectedOrderId: "",
      selectedOrderTypeId: "",
      isLoadingOrderTypes: false,
      isLoadingOrders: false,
      isSubmitting: false,
      isCreatingOrder: false,
      isDirty: false,
      showInvoiced: false,
      showNotInvoiced: true,
      containerColors: [],
      containerTypes: [],
      deliveryDate: format(new Date(), "yyyy-MM-dd"),
      orderModalVisible: false,
      documentsModalVisible: false,
      customerReference: "",
      customerComment: "",
      documents: []
    }
  },

  computed: {

    title() {
      return this.id
    }

  },

  methods: {

    formatNumber(number) {
      return new Intl.NumberFormat(this.$i18n.locale.replace('-sw', ''), { minimumFractionDigits: 0 }).format(number)
    },

    async onDocuments(order) {
      try {
        this.documents = await http.get(`ContainerDocuments?containerOrderId=${order.id}`)
        this.documentsModalVisible = true
      } catch (error) {
        this.$notifyError(error.reason)
      }
    },

    onDocumentsClose() {
      this.documentsModalVisible = false
      this.documents = []
    },

    async onDelete(order) {

      try {
        await http.delete(`ContainerOrder?id=${order.id}`)
        this.getContainerOrders()
      } catch (error) {
        this.$notifyError(error.reason)
      }

    },

    onEdit(order) {
      this.selectedOrderId = order.id
      this.selectedOrderTypeId = order.containerOrderTypeId
      this.deliveryDate = order.deliveryDate
      this.customerReference = order.customerReference
      this.customerComment = order.customerComment
      this.orderModalVisible = true
    },

    onCancelCreate() {
      this.selectedOrderId = ""
      this.selectedOrderTypeId = ""
      this.deliveryDate = format(new Date(), "yyyy-MM-dd")
      this.customerReference = ""
      this.customerComment = ""
      this.orderModalVisible = false
    },

    async onSubmit() {

      this.isSubmitting = true

      try {

        await http.post("ContainerOrder", {
          ...pick(this.order, [
            "id",
            "containerOrderTypeId",
            "customerId",
            "locationId",
            "containerTypeId",
            "containerColorId",
            "customerReference",
            "customerComment",
            "deliveryDate"
          ])
        })

        this.$emit("close")

      } catch (error) {

        this.$notifyError(error.reason)

      }

      this.isSubmitting = false

    },

    async onCreateOrder() {

      this.isCreatingOrder = true

      try {

        let args = {
          containerRentalId: this.container.containerRentalId,
          containerTypeId: this.container.containerTypeId,
          containerOrderTypeId: this.selectedOrderTypeId,
          deliveryDate: this.deliveryDate,
          customerReference: this.customerReference,
          customerComment: this.customerComment
        }

        if (this.selectedOrderId) {
          args.id = this.selectedOrderId
        }

        await http.post("ContainerOrder", args)

        this.createOrderVisible = false

        this.selectedOrderId = ""
        this.containerTypeId = ""
        this.deliveryDate = format(new Date(), "yyyy-MM-dd")
        this.customerReference = ""
        this.customerComment = ""

        this.getContainerOrders()

      } catch (error) {
        this.$notifyError(error.reason)
      }

      this.isCreatingOrder = false

    },


    onClose() {
      this.$emit("close")
    },

    getContainerOrders() {

      this.isLoadingOrders = true

      http.get("ContainerOrders", {
        params: {
          containerRentalId: this.container.containerRentalId,
          withTransactions: true
        }
      }).then(orders => {
        this.orders = orders
        this.isLoadingOrders = false
      })
    },

    getContainerColors() {
      http.get("ContainerColors")
        .then(containerColors => {
          this.containerColors = containerColors
        })
    },

    getContainerTypes() {
      http.get("ContainerTypes")
        .then(containerTypes => {
          this.containerTypes = containerTypes
        })
    },

    getContainerOrderTypes() {

      this.isLoadingOrderTypes = true

      http.get("ContainerOrderTypes")
        .then(orderTypes => {
          this.orderTypes = filter(orderTypes, ot => ot.id !== "NY")
          this.isLoadingOrderTypes = false
        })
    },

    async getContainer(id) {

      this.isLoading = true

      try {

        this.isLoadingContainer = true


        this.container = await http.get(`Container?id=${id}`)
        this.isLoadingContainer = false

        this.getContainerOrderTypes()
        this.getContainerOrders()
        this.getContainerColors()
        this.getContainerTypes()

      } catch (error) {
        this.$notifyError("", error.reason)
      }

      this.isLoading = false
    }
  },

  async created() {
    await this.getContainer(this.id)
  }

}

</script>
